import React from "react"
import css from "./BlogCard.module.css"
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import Img from "gatsby-image"


const BlogCard = ({ post }) => {
  const fluid = post.featuredImage.node.localFile.childImageSharp.fluid
  return (
    <li className={css.card}>
      <div className={css.image}>
      <Img className={css.gatsbyimagewrapper} fluid={fluid} alt={post.featuredImage.node.alt_text}/>
      </div>
      <div className={css.right}>
        <Link to={`/${post.slug}`} className={css.title}>
        <span dangerouslySetInnerHTML={{ __html: post.title }}></span>
        </Link>
        
      </div>
      <div className={css.readMore}>
        <Link to={`/${post.slug}`}>
          <button className={css.more + " button button_secondary"}>Read More</button>
        </Link>
      </div>
      <div className={css.metaSection}>
      <p className={css.dateFont}><FontAwesomeIcon icon={faCalendarAlt} /> <span className={css.date}>{post.date}</span></p>
      </div>
    </li>
  )
}

export default BlogCard
