import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import BlogCard from "../components/blogCard/BlogCard"
import styles from "../css/blog.module.css"
import Layout from "../layouts/index.js"
import SEO from "../components/seo"

export default class BlogList extends Component {
  render() {
    const data = this.props.data
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? '' : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()
    return (
      <Layout>
        <SEO title="Blog" description="Our Blog has many articles which are full of content to compliment your parenting style" />
        <main className={styles.wrapper}>
          <header className={styles.header}>
            <h1>Blog</h1>
          </header>
          {/* <aside className={styles.side}>
            <ul>
              <li>Fathers</li>
              <li>Home Schooling</li>
              <li>Technology</li>
              <li>Discipline</li>
              <li>Islamic Character</li>
            </ul>
          </aside> */}
          <section className={styles.content}>
            <ul className={styles.list}>
              {data.allWpPost.edges.map(({ node }) => (
                <BlogCard post={node} key={node.slug} />
              ))}
            </ul>
            <div className={styles.list}>
              {!isFirst && (
                <Link to={'/blog/' + prevPage} rel="prev">
                  ← Previous Page
                </Link>
              )}
              {!isLast && (
                <Link to={'/blog/' + nextPage} rel="next">
                  Next Page →
                </Link>
              )}
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allWpPost(
        sort: {fields: [date], order: DESC} filter: {status: {eq: "publish"}}
        limit: $limit
        skip: $skip
        ) {
        edges {
            node {
              title
              excerpt
              slug
              date(formatString: "MMMM DD, YYYY")
              featuredImage {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      fluid(maxHeight: 200 ) {
                        ...GatsbyImageSharpFluid_tracedSVG
                      }
                    }
                  }
                }
              }
            }
          }
    }
  }
`